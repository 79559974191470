import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Bold, SmallItallic } from '@/Components/styles';
import { MarginSlider } from './styles';
import { nodeInteractionTracking } from '@/Lib/gtm';
import { StyledBoxOuter, StyledBoxContent } from './styles';

export const toDeFormat = (x, maximumFractionDigits = 8) => {
    return x.toLocaleString('de-DE', { maximumFractionDigits });
};

export const isNull = (val) => {
    if (val === null || isNaN(val)) {
        return true;
    } else {
        return false;
    }
};

export const objectIsEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const generateMarks = (min = 1, max, step = 1) => {
    return Object.fromEntries(arrayGenerator(min, max, step).map((_) => [_, `${_}`]));
};

export const arrayGenerator = (min, max, steps = 1) => {
    min = Math.round(min / Math.min(steps, 1));
    max = Math.round(max / Math.min(steps, 1));
    const arr = Array.from(new Array(max - min + 1), (x, i) => i + min).map((_) => _ * Math.min(steps, 1));
    return arr.filter((e, i) => (i / Math.min(steps, 1)) % Math.max(steps, 1) === 0);
};

export const roundIfNotInteger = (val, decimals = 2) => {
    if (val % 1 !== 0) {
        return val.toFixed(decimals);
    } else {
        return val;
    }
};

export const roundToMultipleOf = (val, multipleOf, mode = 'down') => {
    const func = (mode === 'down') === val > 0 ? Math.ceil : Math.floor;

    if (val > 0) {
        return -1 * func((-1 * val) / multipleOf) * multipleOf;
    } else {
        return func(val / multipleOf) * multipleOf;
    }
};

export const Reminder = ({ header, children, style = {} }) => {
    return (
        <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
            <StyledBoxOuter>
                {header ? (
                    <>
                        <strong style={{ fontSize: '120%' }}>{header}</strong>
                        <br />
                    </>
                ) : null}
                <StyledBoxContent>{children}</StyledBoxContent>
            </StyledBoxOuter>
        </div>
    );
};


export const DefaultMarginSlider = ({ min, max, value, step, setter, onChangeCallback }) => {
    return (
        <MarginSlider
            min={min}
            max={max}
            marks={generateMarks(min, max, step)}
            dots
            step={step}
            included={false}
            value={value}
            onChange={(e) => {
                if (onChangeCallback) {
                    onChangeCallback({
                        pre_interaction_value: value,
                        post_interaction_value: e,
                    });
                }
                setter(e);
            }}
        />
    );
};

const SubSliderSection = ({
    title,
    subTitle,
    titleColor,
    min,
    max,
    value,
    step,
    setter,
    changeHandler,
    labelAboveSlider,
    inputField,
}) => {
    if (!labelAboveSlider) {
        return (
            <Row>
                <Col xs={4} md={3}>
                    <p style={{ color: titleColor }}>
                        <Bold>{title} </Bold>
                        {subTitle ? (
                            <>
                                <br />
                                <SmallItallic>{subTitle}</SmallItallic>
                            </>
                        ) : null}
                    </p>
                </Col>
                <Col xs={8} md={9}>
                    <DefaultMarginSlider
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        setter={setter}
                        onChangeCallback={changeHandler}
                    />
                </Col>
            </Row>
        );
    } else {
        return (
            <>
                {!inputField && (
                    <Row>
                        <Col style={{ color: titleColor }}>
                            <Bold>{title} </Bold>
                            {subTitle ? (
                                <>
                                    <br />
                                    <SmallItallic>{subTitle}</SmallItallic>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                )}
                {inputField && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            whiteSpace: 'no-wrap',
                            color: titleColor,
                        }}
                    >
                        <Bold style={{ marginRight: '10px' }}>{title} </Bold>
                        {subTitle ? (
                            <>
                                <br />
                                <SmallItallic>{subTitle}</SmallItallic>
                            </>
                        ) : null}
                        {inputField}
                    </div>
                )}
                <Row>
                    <Col xs={12}>
                        <DefaultMarginSlider
                            min={min}
                            max={max}
                            step={step}
                            value={value}
                            setter={setter}
                            onChangeCallback={changeHandler}
                        />
                    </Col>
                </Row>
            </>
        );
    }
};

export const SliderSection = ({
    titleOne,
    subTitleOne,
    minOne,
    maxOne,
    valueOne,
    stepOne,
    setterOne,
    changeHandlerOne,
    colorOne = "#5b6b79",
    titleTwo,
    subTitleTwo,
    minTwo,
    maxTwo,
    valueTwo,
    stepTwo,
    setterTwo,
    changeHandlerTwo,
    colorTwo = "#5b6b79",
    nextToEachOther,
    labelAboveSlider = false,
    inputFieldOne,
    inputFieldTwo,
}) => {
    if (!nextToEachOther) {
        return (
            <>
                <SubSliderSection
                    title={titleOne}
                    subTitle={subTitleOne}
                    titleColor={colorOne}
                    min={minOne}
                    max={maxOne}
                    value={valueOne}
                    step={stepOne}
                    setter={setterOne}
                    changeHandler={changeHandlerOne}
                    labelAboveSlider={labelAboveSlider}
                    inputField={inputFieldOne}
                />
                <SubSliderSection
                    title={titleTwo}
                    subTitle={subTitleTwo}
                    titleColor={colorTwo}
                    min={minTwo}
                    max={maxTwo}
                    value={valueTwo}
                    step={stepTwo}
                    setter={setterTwo}
                    changeHandler={changeHandlerTwo}
                    labelAboveSlider={labelAboveSlider}
                    inputField={inputFieldTwo}
                />
            </>
        );
    } else {
        return (
            <Row>
                <Col xs={12} lg={6}>
                    <SubSliderSection
                        title={titleOne}
                        subTitle={subTitleOne}
                        titleColor={colorOne}
                        min={minOne}
                        max={maxOne}
                        value={valueOne}
                        step={stepOne}
                        setter={setterOne}
                        changeHandler={changeHandlerOne}
                        labelAboveSlider={labelAboveSlider}
                        inputField={inputFieldOne}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <SubSliderSection
                        title={titleTwo}
                        subTitle={subTitleTwo}
                        titleColor={colorTwo}
                        min={minTwo}
                        max={maxTwo}
                        value={valueTwo}
                        step={stepTwo}
                        setter={setterTwo}
                        changeHandler={changeHandlerTwo}
                        labelAboveSlider={labelAboveSlider}
                        inputField={inputFieldTwo}
                    />
                </Col>
            </Row>
        );
    }
};

export const InputEntry = ({ nodeId, value, setter, onChangeCallback, paramName, textColor, symbol, disabled }) => {
    return (
        <Form.Group style={{ marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
            <InputGroup>
                <Form.Control
                    type='number'
                    placeholder='?'
                    style={{
                        textAlign: 'center',
                        maxWidth: '75px',
                        fontWeight: 400,
                        color: textColor,
                    }}
                    value={!value ? '' : value}
                    onChange={(e) => {
                        if (value !== '') {
                            setter(parseFloat(e.target.value));
                            if (onChangeCallback) {
                                onChangeCallback({ newValue: parseFloat(e.target.value) });
                            };
                            nodeInteractionTracking({ node_id: nodeId, paramName })({
                                pre_interaction_value: !value ? '' : value,
                                post_interaction_value: parseFloat(e.target.value),
                            });
                        };
                    }}
                    disabled={disabled}
                />
                <InputGroup.Append>
                    <InputGroup.Text>{symbol}</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>
    );
};
