import { useState, useEffect, useRef } from 'react';
import KaTeX from 'katex';
import 'katex/dist/katex.min.css';

const generateHtml = ({ displayMode, errorColor, renderError, input }) => {
    try {
        const html = KaTeX.renderToString(input, {
            displayMode,
            errorColor,
            throwOnError: !!renderError,
            strict: false,
            trust: true,
        });
        return { html, error: undefined };
    } catch (error) {
        if (error instanceof KaTeX.ParseError || error instanceof TypeError) {
            return { error };
        }
        throw error;
    }
};

const createMathComponent = (Component, { displayMode }) => {
    const MathComponent = ({ children, errorColor, math, renderError }) => {
        const mode = math ? 'math' : 'children';
        const input = mode === 'math' ? math : children;
        const [state, setState] = useState(generateHtml({ displayMode, errorColor, renderError, input }));

        const mounted = useRef(false);

        useEffect(() => {
            if (mounted.current) {
                setState(generateHtml({ displayMode, errorColor, renderError, input }));
            } else {
                mounted.current = true;
            }
        }, [children, errorColor, math, renderError]);

        const { error, html } = state;

        if (error) {
            return renderError ? renderError(error) : <Component html={`${error.message}`} />;
        }

        return <Component html={html} />;
    };

    return MathComponent;
};

export default createMathComponent;
