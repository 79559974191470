import styled from 'styled-components';
import Slider from 'rc-slider';

export const MarginSlider = styled(Slider)`
    margin-top: 10px;
    margin-bottom: 30px;
`;

export const StyledBoxOuter = styled('div')`
    border-left: solid 7px rgb(206, 188, 11);
    border-radius: 5px 10px 10px 5px;
    margin-bottom: 10px;
    padding: 5px 5px 5px 15px;
    background: #f0eb0d66;
`;

export const StyledBoxContent = styled('div')`
    font-family: Roboto Mono;
`;
