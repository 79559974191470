import BlockMath from './BlockMath'
import InlineMath from './InlineMath'
import { FormulaSizeSmall, FormulaSize } from '@/Components/styles';

export const Formula = ({ formula, size = 1 }) => {
    return (
        <FormulaSizeSmall size={size}>
            <BlockMath>{formula}</BlockMath>
        </FormulaSizeSmall>
    );
};

export const FormulaBig = ({ formula }) => {
    return (
        <FormulaSize>
            <BlockMath>{formula}</BlockMath>
        </FormulaSize>
    );
};

export const FormulaText = ({ formula }) => {
    return <InlineMath>{formula}</InlineMath>;
};